<template>
  <button
    class="label-base-2 flex h-[3.75rem] w-full items-center justify-center gap-2 bg-blue-700 px-5 py-[18px] leading-[110%] text-white"
  >
    {{ t("label") }}
  </button>
</template>

<script lang="ts" setup>
const { t } = useI18n({
  useScope: "local",
  messages: {
    cs: {
      label: "Hotovo",
    },
    en: {
      label: "Done",
    },
  },
});
</script>
