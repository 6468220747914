import { useQuery } from "@tanstack/vue-query";
import type { FiltrationTagsOption } from "~/components/filtration/FiltrationTags.vue";

interface AreaAPI {
  id: number;
  level: number;
  slug: string;
  name: string;
  label: string;
  icon: null | string;
  children: AreaAPI[];
}

export const useOfferAreasQuery = () => {
  const queryKey = ["offer-areas"];

  return useQuery({
    queryKey,
    queryFn: async () => {
      const result = await $apiFetch<AreaAPI[]>(`/api/areas`);

      return transformAreas(result);
    },
  });
};

const transformAreas = (data: AreaAPI[]): FiltrationTagsOption[] => {
  return data.map(({ label, slug, children, id }) => ({
    id: slug,
    label,
    children: transformAreas(children),
    idx: id,
  }));
};
