<template>
  <ComboboxInput ref="button" class="h-0 w-0 overflow-hidden outline-none" />
</template>

<script setup lang="ts">
import { ComboboxInput } from "@headlessui/vue";

const button = ref(null);

const isVisible = useElementVisibility(button);

watch(isVisible, (yes) => {
  if (yes) {
    useTimeoutFn(() => button.value.$el.focus(), 300);
    return;
  }

  button.value.$el.blur();
});
</script>
