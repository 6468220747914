<template>
  <TransitionRoot as="template" :show="props.open">
    <Dialog as="div" class="relative z-50" @close="emit('close')">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden bg-black/10">
          <div
            class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full md:pl-16"
          >
            <TransitionChild
              as="template"
              :enter="
                props.disableEnterTransition
                  ? 'transition-opacity ease-linear duration-300'
                  : 'transform transition ease-in-out duration-500 sm:duration-700'
              "
              :enter-from="
                props.disableEnterTransition ? 'opacity-0' : 'translate-x-full'
              "
              :enter-to="
                props.disableEnterTransition ? 'opacity-100' : 'translate-x-0'
              "
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel
                :class="[
                  props.wide ? 'max-w-[768px]' : 'max-w-md',
                  'pointer-events-auto w-screen',
                ]"
              >
                <div
                  class="isolate flex h-[100dvh] flex-col bg-white shadow-xl"
                >
                  <div
                    :style="{ 'max-height': visualHeight }"
                    class="flex h-full flex-col"
                  >
                    <div
                      :class="[
                        !props.paddingXZero && 'px-6',
                        'z-10 h-full flex-1 grow overflow-y-auto',
                      ]"
                    >
                      <div
                        v-if="props.levels > 0"
                        class="flex h-full flex-grow overflow-x-hidden"
                      >
                        <div
                          v-for="level in range"
                          :key="`level${level}`"
                          :style="{
                            transform: `translateX(-${
                              level * 100 + (currentLevel - level - 1) * 100
                            }%)`,
                          }"
                          class="flex w-full shrink-0 flex-col overflow-y-auto transition-transform duration-500"
                        >
                          <slot
                            :name="`level${level}`"
                            :next="next"
                            :prev="back"
                          />
                        </div>
                      </div>

                      <slot v-else />
                    </div>

                    <div>
                      <slot name="button" />
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

const props = withDefaults(
  defineProps<{
    open: boolean;
    levels?: number;
    wide?: boolean;
    paddingXZero?: boolean;
    disableEnterTransition?: boolean;
  }>(),
  {
    levels: 0,
    wide: false,
    paddingXZero: false,
    disableEnterTransition: false,
  },
);
const emit = defineEmits<{ close: [] }>();

const range = computed(() =>
  Array.from(new Array(props.levels), (x, i) => i + 1),
);
const currentLevel = ref(1);

const next = () => currentLevel.value++
const back = () => currentLevel.value--

defineExpose({ currentLevel, next });

const visualHeight = ref(null);

const resizeHandler = () => {
  visualHeight.value = `${window.visualViewport?.height}px`;
};

onMounted(() => {
  window.visualViewport?.addEventListener("resize", resizeHandler);
});

onUnmounted(() => {
  window.visualViewport?.removeEventListener("resize", resizeHandler);
});

const handleFocus = (event) => {
  if (
    event.target.matches(
      'input[type="text"], input[type="password"], input[type="email"], input[type="number"], input[type="date"], input[type="search"], input[type="tel"], input[type="url"], textarea, [contenteditable="true"]',
    ) &&
    window.visualViewport?.width < 768
  ) {
    setTimeout(() => {
      window.scrollTo(0, -1);

      setTimeout(() => {
        event.target.scrollIntoView({
          block: "end",
          behavior: "smooth",
        });
      }, 400);
    }, 200);
  }
};

onMounted(() => {
  window.addEventListener("focusin", handleFocus, true);
});

onUnmounted(() => {
  window.removeEventListener("focusin", handleFocus, true);
});
</script>
